<template>
  <div>
    <dashboard-page-title
      :showAddBtn="false"
      :showFilter="false"
      :showSearch="true"
      :showExportation="true"
      >الحجوزات</dashboard-page-title
    >
    <main-table :fields="fields" query="&status=active" list_url="doctor/reservations"></main-table>
  </div>
</template>
<script>
/*eslint-disable*/
import { core } from "@/config/pluginInit";
export default {
  components: {},
  mounted() {
    core.index();
  },
  data() {
    return {
      fields: [
        { label: "Id", key: "id", class: "text-right", sortable: true },
        { label: "اسم العميل", key: "customer_name", class: "text-right" },
        { label: "تكلفة الكشف", key: "price", class: "text-right" },
        {
          label: "تاريخ الزيارة",
          key: "time",
          class: "text-right",
          type: "time",
        },
        {
          label: "نوع الحجز",
          key: "service_type",
          class: "text-right",
        },
        { label: "تاريخ الإنشاء", key: "created_at", class: "text-right" },
        {
          label: "التحكم",
          key: "actions",
          class: "text-right",
          type: "actions",
          actions: [
            {
              text: "تم الحضور",
              icon: "ri-check-line",
              color: "primary"
            },
            {
              text: "إلغاء",
              icon: "ri-close-circle-line",
              color: "danger"
            },
          ],
        },
      ],
    };
  },
};
</script>
